<template>
  <ion-page>
    <ion-header mode="md" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons>
          <ion-button @click="$router.back()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 12H5" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 19L5 12L12 5" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div id="container">
        <div class="form">
          <div class="title mb-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Password Anda
          </div>
          <div class="input-group">
            <ion-input :type="see? 'text': 'password'" class="input" placeholder="password" v-model="password" @keyup.enter="login"></ion-input>
            <span class="after" @click="see=!see">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
          </div>
          <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn" @click="login">Masuk</ion-button>
        </div>
        <div class="forgot mt-2">
          <a @click="$router.push('/forgot-password')" class="link">Lupa password?</a>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonHeader, IonToolbar,  IonButtons, IonButton, IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonInput
  },
  data () {
    return {
      password: null,
      see: false
    }
  },
  methods: {
    login () {
      let email = this.$route.query.email
      if(email === undefined || email === '')
        return;

      this.$store.dispatch('auth/login', {email: email, password: this.password})
        .then(res => {
          if(res && ! res.error)
            this.$router.push('/')
        })

    }
  }
})
</script>

<style lang="scss">
#container {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 30%;
  transform: translateY(-20%);
  padding: 1rem;
}
.title {
  font-size: 24px;
  // margin-bottom: 2rem;
}
.link {
  color: #000000;
}
</style>
